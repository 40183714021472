<template>
  <div
    class="auth-layout-wrap aanvragen"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
    <Form :data="questions" @submit="submit" :loadingProgress="loadingProgress" ></Form>
  </div>
</template>

<script>
import Form from "./Form"

export default {
  metaInfo: {
    title: "Inloggen"
  },
  components: {
    Form
  },
  data() {
    return {
      email: "",
      password: "",
      userId: "",
      loadingProgress: 0,
      bgImage: require("@/assets/images/background-login.jpg"),
      logo: require("@/assets/images/logo-inverse.png"),
      questions: [
          {
              type: "info",
              description: "Wij helpen jou met verstandig verduurzamen. Hoe weet je dat het binnenklimaat comfortabel en gezond is in het huis waar je woont en werkt? Wij nemen je mee door een aantal vragen om een sensor aan te vragen. Hiermee kan je beginnen met het reguleren van jouw binnenklimaat!",
              label: "Welkom"
          },
          {
              type: "name",
              name: "name",
              label: "Wat is jouw naam?",
              description: "Om een aanvraag in te dienen hebben we enkele gegevens van je nodig.",
              value: ["",""],
              validation_eror: "Graag een voornaam en achternaam opgeven",
              validation: function(q) {
                  return q.value[0].length > 1 && q.value[1].length
              }
          },
          {
              type: "email",
              name: "email",
              label: "Wat is jouw email?",
              description: "Met jouw email houden we je op de hoogte van de mogelijkheden bij jou in de buurt!",
              placeholder: "Email",
              value: "",
              validation_eror: "Graag een valide email toevoegen",
              validation: function(q) {
                  return q.value.indexOf("@") > -1 && q.value.split("@")[1].indexOf(".") > -1
              }
          },
          {
              type: "address",
              name: "address",
              label: "Address van jouw woning",
              placeholder: "Address",
              loading: false,
              done: false,
              value: ["","","","","Nederland"],
              validation_eror: "Graag een valide address toevoegen",
              validation: function(q) {
                  return q.value[0].length > 2 && q.value[1].length > 0 && q.value[2].length > 2 && q.value[3].length > 2
              }
          },
          {
              type: "tc",
              name: "tc",
              label: "Terms and conditions",
              description: "Ik ga akkoord met de <u><a class='text-white' href='/media/Privacyverklaring.pdf' download>privacy statement</a></u> en de <u><a class='text-white' href='/media/algemene-voorwaarden-21-sept.pdf' download>algemene voorwaarden</a></u>. Tevens sluit ik enige aansprakelijkheid van GHub uit.",
              value: [],
              validation_eror: "Zonder akkoord kunnen we niet verder.",
              validation: function(q) {
                  console.log(q)
                  return q.value[0]
              }
          }
      ]
    };
  },
  computed: {
    
  },

  methods: {
      submit(formData, mapper, endLoading) {
          let _this = this
          this.$http.post("/sensor/aanvraag", formData, {
              onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')
                
                if (totalLength !== null) {
                    _this.loadingProgress = Math.round( (progressEvent.loaded * 100) / totalLength )
                    console.log(_this.loadingProgress)
                }
              }
          }).then((response) => {
              if(response.data.status === "success") {
                  _this.$router.push({name: "dank"})
              }
              endLoading()
          }).catch((e) => {
              if(e.response && e.response.data.errors) {
                  Object.values(e.response.data.errors).forEach((error)=> {
                      _this.$bvToast.toast(error[0], {
                          title: `Error submitting project`,
                          variant: 'danger',
                          solid: true
                      })
                  })
                    endLoading()
              }
          })
        }
  },
  watch: {
    
  }
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}

.aanvragen {
  border-radius: 15px;
  margin-top:2em;
  position: relative;
  display: block;
  background-position: center;
}
</style>
