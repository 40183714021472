<template>
    <b-form ref="form" enctype="multipart/form-data" class="form" style="position: absolute; right: 0; left: 0; bottom: 0; top: 0; overflow:hidden">
        <div class="background"  :style="fullscreen ? `top:${form.offset}px`: ''">
            
        </div>
        <b-container class="question-wrapper" :style="fullscreen ? `height: calc(100% - ${form.offset}px); margin-top: ${form.offset}px`: 'height: 100%; margin-top: 0'">
            <div class="loading" v-bind:style="{height:  `calc(${loading}% - 2em)`, top: `calc(50% - ${loading/2}% + 1em)`, opacity: hideloading ? '0' : '1'}" v-bind:class="{complete: loading === 100.0, error: form.validationError}"></div>
            <div class="current d-none">{{form.currentQuestion+1}}/{{questions.length}}</div>
            <div v-for="(question, index) in questions" :key="index">
                <b-container class="question" v-bind:class="{show: form.currentQuestion == index, hide: form.previousQuestion == index, back: (form.currentQuestion < form.previousQuestion), 'd-none': (index != form.previousQuestion && index != form.currentQuestion)}">
                    <b-row>
                        <b-col>
                            <b-container style="padding-top: 20%">
                                <h1>{{question.label}}</h1>
                                <div v-if="question.type == 'info'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                </div>
                                <div v-if="question.type == 'text'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-form-input required :placeholder="question.placeholder" v-model="question.value" :name="question.name"></b-form-input>
                                </div>
                                <div v-if="question.type == 'textarea'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-textarea required :placeholder="question.placeholder" v-model="question.value" :name="question.name"></b-textarea>
                                </div>
                                <div v-if="question.type == 'email'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-form-input required :type="question.type" :placeholder="question.placeholder" v-model="question.value" :name="question.name"></b-form-input>
                                </div>
                                <div v-if="question.type == 'url'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-form-input required :type="question.type" :placeholder="question.placeholder" v-model="question.value" :name="question.name"></b-form-input>
                                </div>
                                <div v-if="question.type == 'tags'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-form-tags placeholder="Voeg label toe" class="labels" tag-pills required input-id="tags-basic" v-model="question.value" :name="question.name">
                                       
                                    </b-form-tags>
                                </div>
                                <div v-if="question.type == 'name'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-row>
                                        <b-col cols="3">
                                            <b-form-group class="font-weight-bold" label="Voornaam">
                                                <b-form-input required placeholder="Jan" v-model="question.value[0]" name="firstName"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-group class="font-weight-bold" label="Achternaam">
                                                <b-form-input required placeholder="Jansen" v-model="question.value[1]" name="lastName"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-if="question.type == 'address'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-row>
                                        <b-col>
                                            <b-form-group  class="font-weight-bold" label="Postcode">
                                                <b-form-input required type="text" placeholder="1123AB" @blur="lookupAddress(index)" v-model="question.value[0]" name="postcode"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group  class="font-weight-bold" label="Huisnummer">
                                                <b-form-input required type="text" placeholder="12" @blur="lookupAddress(index)" v-model="question.value[1]" name="housenumber"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row> 
                                    <b-row v-if="question.done">
                                        <b-col>
                                            <b-form-group  class="font-weight-bold" label="Straat">
                                                <b-form-input :disabled="true" required type="text" placeholder="Marnixstraat" v-model="question.value[2]" name="street"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group  class="font-weight-bold" label="Stad">
                                                <b-form-input :disabled="true" required type="text" placeholder="Amsterdam" v-model="question.value[3]" name="city"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="question.loading">
                                        <b-col>
                                            <div class="loading-address"><b-spinner class="spinner" variant="light"></b-spinner><span>Loading address...</span></div>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-if="question.type == 'video'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-form-file
                                        v-model="question.value"
                                        :name="question.name"
                                        required
                                        :placeholder="question.placeholder"
                                        :drop-placeholder="question.drop_placeholder"
                                        accept="video/*"
                                    ></b-form-file>
                                    <b-button class="next" @click="question.value = []">Verwijder video</b-button>
                                </div>
                                <div v-if="question.type == 'file'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-form-file
                                        v-model="question.value"
                                        :name="question.name"
                                        required
                                        :placeholder="question.placeholder"
                                        :drop-placeholder="question.drop_placeholder"
                                        accept="image/jpeg, image/png"
                                    ></b-form-file>
                                    <b-button class="next" @click="question.value = []">Verwijder afbeelding</b-button>
                                </div>
                                <div v-if="question.type == 'tc'">
                                    <p v-if="question.description" v-html="question.description"></p>
                                    <b-form-checkbox-group
                                        v-model="question.value"
                                        class="checkbox"
                                        :name="question.name"
                                    >
                                        <b-form-checkbox required value="true">{{question.label}}</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                                <div v-if="form.validationError" class="valitation-error">
                                    {{question.validation_eror}}
                                </div>
                                <b-button v-if="index != questions.length - 1" class="next" @click="next">NEXT</b-button>

                                <b-row v-if="index == questions.length - 1 && form.submitLoading">
                                    <b-col style="color:white">
                                        <b-spinner class="spinner" variant="light"></b-spinner><span>Uploading data, this may take a while... {{loadingProgress}}%</span>
                                    </b-col>
                                </b-row>
                                <b-button v-if="index == questions.length - 1" :disabled="form.submitLoading" class="submit" @click="submit">Submit</b-button>
                            </b-container>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <div v-if="form.currentQuestion != 0" class="back" @click="back"><div class="icon-wrapper"><svg class="icon" viewBox="0 0 12 7"><path class="svg" d="M1.707 6.692A1 1 0 01.293 5.278L5.278.293a1 1 0 011.414 0l4.985 4.985a1 1 0 01-1.414 1.414L5.985 2.414 1.707 6.692z" fill="#fff"></path></svg></div><span class="text">Terug naar <i>{{questions[form.currentQuestion-1].label}}</i></span></div>
            
        </b-container>
    </b-form>

</template>

<script>
const TWEEN = require('@tweenjs/tween.js')

export default {
    computed: {
        loading() {
            return ((this.form.currentQuestion+1)/this.questions.length * 100.0)
        }
    },
    props: {
        data: {
            type: Array,
            default : () => {return []}
        },
        fullscreen: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: "500px"
        },
        hideloading: {
            type: Boolean,
            default: false
        },
        loadingProgress: {
            type: Number,
            default:0
        }
    },
    mounted() {
        let _this = this
        //console.log(document.body.getElementsByClassName("navbar")[0])
       // this.form.offset = document.body.getElementsByClassName("navbar")[0].clientHeight

        /*document.addEventListener("keyup", (e) => {
            if (e.key === 'Enter') {
                _this.next()
            }
        })*/

        this.questions = this.data
        this.questions.forEach(q => {
            _this.form.mapper.set(q.name, q)
        })
    },
    methods: {
        animate(time) {
            requestAnimationFrame(this.animate)
            TWEEN.update(time)
        },
        lookupAddress: function(index) {
            this.questions[index].loading = true
            this.$http.get(`/project/address/${this.questions[index].value[0]}/${this.questions[index].value[1]}`).then((response) => {
                if(response.data.status === "success") {
                    if(!response.data.response.message) {
                        let data = response.data.response
                        this.questions[index].value = [this.questions[index].value[0], this.questions[index].value[1], data.street, data.city, "Nederland"]
                        this.questions[index].done = true
                    } else {
                        this.questions[index].done = false
                    }
                } else {
                    this.questions[index].done = false
                }
                this.questions[index].loading = false
            }).catch(() => {
                this.questions[index].done = false
            })
        },
        submit: function() {
            if(!(this.form.bypassValidation || !this.questions[this.form.currentQuestion].validation || this.questions[this.form.currentQuestion].validation(this.questions[this.form.currentQuestion]))) {
                this.form.validationError = true
                return
            }
            
            var formData = new FormData()

            this.questions.forEach((question) => {
                switch(question.type) {
                    case "name" : {
                        formData.append("firstName", question.value[0])
                        formData.append("lastName", question.value[1])
                    } break
                    case "textarea": {
                        formData.append(question.name, question.value)
                    } break
                    case "tc": {
                        formData.append(question.name, question.value)
                    } break
                    case "url": {
                        formData.append(question.name, question.value)
                    } break
                    case "video": {
                        formData.append(question.name, question.value)
                    } break
                    case "file": {
                        formData.append(question.name, question.value)
                    } break
                    case "email": {
                        formData.append(question.name, question.value)
                    } break
                    case "text": {
                        formData.append(question.name, question.value)
                    } break
                    case "tags": {
                        formData.append("tags", JSON.stringify(question.value))
                    } break
                    case "address": {
                        formData.append("postcode", question.value[0])
                        formData.append("housenumber", question.value[1])
                        formData.append("street", question.value[2])
                        formData.append("city", question.value[3])
                        formData.append("country", question.value[4])
                    } break
                }
            })
            this.form.submitLoading = true
            this.$emit("submit", formData, this.form.mapper , this.endLoading)
        },
        endLoading: function() {
            this.form.submitLoading = false
        },
        back: function () {
            if(this.form.currentQuestion != 0) {
                this.form.previousQuestion = this.form.currentQuestion
                this.form.currentQuestion -= 1
                this.form.validationError = false
            }
        },
        next: function() {
            if(this.form.currentQuestion != this.questions.length-1) {
                if(this.form.bypassValidation || !this.questions[this.form.currentQuestion].validation || this.questions[this.form.currentQuestion].validation(this.questions[this.form.currentQuestion])) {
                    this.form.previousQuestion = this.form.currentQuestion
                    this.form.currentQuestion += 1
                    this.form.validationError = false
                } else {
                    this.form.validationError = true
                }
            }
        },
    },
    data() {
        return {
            form: {
                offset: 0,
                mapper: new Map(),
                currentQuestion: 0,
                previousQuestion: -1,
                validationError: false,
                bypassValidation: false,
                submitLoading: false
            },
            questions: []
        }
    }
}
</script>

<style lang="scss">
    .labels {
        .b-form-tag {
            background-color: #ff6600ff;
            color: white;
            padding-left: 1em;
            padding-right: 1em;
        }

        .b-form-tag-remove {
            color:white !important;
        }

        input {

            color: white;
        }
    }
</style>

<style lang="scss" scoped>

    .form {
        overflow: hidden;
    }

    .background {
        position: absolute;
        top:0; left:0; right:0; bottom:0;
        opacity: 0.6;
        background: darkblue;
        border-radius: 15px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .question-wrapper {
        position: relative;
        height: 100%;

        .loading-address {
            color: white;

            .spinner {
                float:left;
            }

            span {
                float:left;
                line-height: 2rem;
                margin-left: 1em;
                font-weight: bold;
            }
        }

        .checkbox {
            color: white;
        }

        .b-form-tags {
            background: none;
            border: none;
            border-bottom: 2px solid white;
            border-radius: 0;

            .b-form-tags-input {
                color: white !important;
            }
        }

        fieldset {
            color: white !important;
        }

        .back {
            color: white;
            cursor: pointer;
            z-index: 999 !important;
            position: absolute;
            left: 2em;
            top: 2em;
            line-height: 1.5em;

            .text {
                transition: all 0.5s ease-in-out;
            }

            .icon-wrapper {
                background-color: rgba(255,255,255,0.5);
                float: left;
                border-radius: 50%;
                width: 1.5em;
                height: 1.5em;
                margin:0; padding:0;
                position: relative;
                margin-right: 0.5em;
                
                transition: all 0.6s ease-in-out;

                .icon {
                    display: block;
                    width: 0.6em;
                    margin: 0 auto;
                    margin-top: 35%;
                }
            }

            &:hover {

                .text {
                    border-bottom: 2px solid white;
                }

                .icon-wrapper {
                    background-color: rgba(255,255,255,1);

                    .svg {
                        fill: black;
                    }
                }
            }   
        }

        .next, .submit {
            background: none;
            color: white;
            margin-top: 1em;
        }

        input[type='text'], input[type='email'], input[type='url'], textarea {
            background:none;
            border: none;
            border-radius: 0;
            
            border-bottom: 2px solid grey;

            transition: all 0.1s ease-in-out;
            
            color: white;


            &:focus {
                border-bottom: 4px solid white;
            }
        }

        .current {
            color: white;
            position: absolute;
            left: 0;
            top: 10%;
            transform: rotate(-90deg);
        }

        .loading {
            height: 0;
            width: 0.8em;
            border-radius: 0.5em;
            background: white;
            position: absolute;
            left: 0;
            top: 0;

            transition: all 0.2s ease-in-out;

            &.complete {
                /*background: #ff6600ff;*/
            }

            &.error {
                background: #ff6600ff;
            }
        }

        h1, p {
            color: white;
        }
        h1 {

            font-weight: bold;
        }

        p {
            font-size: 1.2em;
        }

        .question {
            position: absolute;
            top: -100%; left:0; right:0; bottom:0;
            overflow: hidden;
            height: 100%;
            display: block;
            z-index: 0;

            .valitation-error {
                color: white;
                font-weight: bold;
                margin-top: 1em;
            }

            @keyframes slideOutToBottom {
                from {
                    top:0;
                    opacity: 1;
                }

                to {
                    top: 100%;
                    opacity: 0;
                    z-index: -1;
                }
            }

            @keyframes slideOutToTop {
                from {
                    top:0;
                    opacity: 1;
                }

                to {
                    top: -100%;
                    opacity: 0;
                    visibility: hidden;
                }
            }

            @keyframes slideInFromTop {
                from {
                    top: -100%;
                    opacity: 0;
                }

                to {
                    top: 0;
                    opacity: 1;
                    z-index: 0;
                }
            }

            @keyframes slideInFromBottom  {
                from {
                    top: 100%;
                    opacity: 0;
                }

                to {
                    top: 0;
                    opacity: 1;
                    z-index: 0;
                }
            }

            &.show {
                animation: slideInFromBottom 0.6s 1;
                animation-fill-mode: forwards;

                &.back {
                    animation: slideInFromTop 0.6s 1;
                    animation-fill-mode: forwards;
                }
            }

            &.hide {
                animation: slideOutToTop 0.6s 1;
                animation-fill-mode: forwards;

                &.back {
                    animation: slideOutToBottom 0.6s 1;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
</style>